import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import PortfolioContext from '../../context/context';

const About = () => {
  const { about } = useContext(PortfolioContext);
  const { img, paragraphOne, paragraphTwo, paragraphThree, resume } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about">
      <Container>
        <Title title="About Me" />
        <Row className="about-wrapper">
          <Col md={6} sm={12}>
            <Fade bottom duration={1000} delay={600} distance="30px">
              <div className="about-wrapper__image">
                <AboutImg alt="profile picture" filename={img} />
              </div>
            </Fade>
          </Col>
          <Col md={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="about-wrapper__info">
                <p className="about-wrapper__info-text">
                My name is Prabin Deka. I am a Director & Co-founder at Kenekt.
                </p>
                <p className="about-wrapper__info-text">
                I am passionate about simplifying technology for my clients. I have pursued the same in my work with the online divisions at TAB, WooliesX, Suncorp, Realestate Australia, Lonely Planet, TAB, Getup!, Telstra and Macquarie Bank.
                </p>
                <p className="about-wrapper__info-text">
                I am a passionate technologist and love working with small, highly focused teams to solve complex technology problems. I have nearly two decades of experience, primarily in highly dynamic projects, where challenging the status quo was a norm.
                </p>
                <p className="about-wrapper__info-text">
                I have an MBA from AGSM, which has given me a holistic perspective on business issues and a sophisticated view on motivation.
                </p>
                <span className="d-flex mt-3">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cta-btn cta-btn--resume"
                    href={resume || ''}
                  >
                    Resume
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cta-btn cta-btn--resume ml-2"
                    href="https://github.com/pdeka"
                  >
                    Github
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cta-btn cta-btn--resume ml-2"
                    href="https://au.linkedin.com/in/prabindeka"
                  >
                    Linkedin
                  </a>
                </span>
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
